// ref: https://github.com/DavidWells/analytics/blob/master/packages/analytics-plugin-lifecycle-example/src/index.js

export type LogConfig = {
  page: boolean
  track: boolean
  identify: boolean
  reset: boolean
}

export const defaultConfig: LogConfig = {
  page: true,
  track: true,
  identify: true,
  reset: true,
}

/**
 *
 */
export default function analyticsLogPlugin(userConfig: Partial<LogConfig> = defaultConfig) {
  return {
    name: 'analytics-plugin-tegrus-analytics-log',
    config: {
      ...defaultConfig,
      ...userConfig,
    },
    bootstrap: function ({ config, instance }) {
      const allowedEvents = Object.keys(config).filter((key) => config[key])

      instance.on('*', ({ payload }) => {
        if (allowedEvents.includes(payload.type)) {
          console.log(payload)
        }
      })
    },
  }
}
