// ref: https://github.com/DavidWells/instance/blob/master/packages/instance-plugin-lifecycle-example/src/index.js

import type { AnalyticsInstance } from 'analytics'

type Config = {
  appName: string
  nextPage: boolean
  previousPage: boolean
  identify: boolean
  rendered: boolean
  submitted: boolean
}

const defaultConfig: Config = {
  appName: '',
  nextPage: true,
  previousPage: true,
  identify: true,
  rendered: true,
  submitted: true,
}

/**
 *
 */
export default function iscFormPlugin(userConfig: Partial<Config> = defaultConfig) {
  return {
    name: 'analytics-plugin-tegrus-isc-form',
    config: {
      ...defaultConfig,
      ...userConfig,
    },
    bootstrap: function ({ config, instance }: { config: Config; instance: any }) {
      /* add form events listerners before render */
      listenToFormEvents(config, instance)
    },
  }
}

/**
 *
 */
const listenToFormEvents = (config: Config, instance: AnalyticsInstance) => {
  const allowedEvents = Object.fromEntries(
    Object.entries(config).filter(([_, value]) => value === true)
  )

  allowedEvents.rendered &&
    window.addEventListener('formio-form-rendered', (e: CustomEvent) => {
      instance.track('Form Rendered', e.detail)
    })

  allowedEvents.identify &&
    window.addEventListener('formio-user-identify', (e: CustomEvent) => {
      instance.identify(e.detail.userId, { ...e.detail.data })
    })

  allowedEvents.nextPage &&
    window.addEventListener('formio-next-page', (e: CustomEvent) => {
      instance.track('Form Next', e.detail)
    })

  allowedEvents.rendered &&
    window.addEventListener('formio-previous-page', (e: CustomEvent) => {
      instance.track('Form Previous', e.detail)
    })

  allowedEvents.submitted &&
    window.addEventListener('formio-form-submitted', (e: CustomEvent) => {
      instance.track('Form Submitted', e.detail)
    })
}
