import Analytics from 'analytics'
import lastClickPlugin from './analytics/plugins/last-click/plugin'
import analyticsLogPlugin from './analytics/plugins/analytics-log/plugin'
import iscFormPlugin from './analytics/plugins/isc-form/plugin'
import utmPlugin from './analytics/plugins/utm/plugin'
import naveggPlugin from './analytics/plugins/navegg/plugin'
import googleTagManager from '@analytics/google-tag-manager'
import '@tegrus/formiojs/dist/formio.form.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import { environment } from './utils/environment'

type Options = {
  gtm?: string
  navegg?: string
}

/**
 *
 */
const init = async (appName: string, { gtm, navegg }: Options = {}) => {
  const addGoogleTagManager = gtm != null
  const addNavegg = navegg != null
  const plugins = [lastClickPlugin(), analyticsLogPlugin(), utmPlugin(), iscFormPlugin({ appName })]

  if (addGoogleTagManager) {
    plugins.push(googleTagManager({ containerId: gtm }))
  }

  if (addNavegg) {
    plugins.push(naveggPlugin({ acc: navegg }))
  }

  const analytics = Analytics({
    app: 'tegrus-analytics',
    debug: !environment.isProd,
    plugins,
  })

  // for debug purposes
  window['__analytics'] = analytics

  analytics.ready((payload) => {
    /* Log ready state */
    console.log('[Analytics ready] => all plugins have loaded or were skipped', payload)

    /* Fire first page view */
    analytics.page()

    /* trigger custom event */
    window.dispatchEvent(new Event('analytics-ready'))

    /* render form */

    const element = document.getElementById('formio')

    if (element && element.dataset.formId) {
      renderForm(appName, analytics.user())
    }
  })
}

/**
 *
 */
const renderForm = (appName: string, user) => {
  // lazy load render function
  import('./formio/render').then(({ render }) => {
    render({
      appName,
      user,
      environment,
    })
  })
}

export const isc = {
  init,
}
