export type EnvName = 'development' | 'production'

export type Env = {
  name: EnvName
  isProd: boolean
  iscForm: {
    apiUrl: string
    metadataUrl: string
  }
  sentry: {
    dsn: string
    release: string
    enabled: boolean
    project: string
    trace?: {
      sampleRating?: number
      propagationTargets?: (string | RegExp)[]
    }
  }
}

//General
const isProd = import.meta.env.MODE === 'production'
const name = import.meta.env.MODE as EnvName

// Sentry
const dsn = import.meta.env.VITE_SENTRY_DSN
const sentryEnabled = import.meta.env.VITE_SENTRY_ENABLED
const project = import.meta.env.VITE_SENTRY_PROJECT
const release = window['SENTRY_RELEASE']?.id
const sampleRating = isProd ? 0.1 : 1.0
const propagationTargets = isProd
  ? /^https:\/\/api-isc\.tegrus\.com\.br/
  : /^https:\/\/api-isc-dev\.tegrus\.io/

// isc form
const apiUrl = isProd ? 'https://api-isc.tegrus.com.br' : 'https://api-isc-dev.tegrus.io'
const metadataUrl = `https://cdn-isc.tegrus.io/${name}/forms`

export const environment: Env = {
  name,
  isProd,
  iscForm: {
    apiUrl,
    metadataUrl,
  },
  sentry: {
    dsn,
    release,
    enabled: sentryEnabled,
    project,
    trace: {
      sampleRating,
      propagationTargets: ['localhost', /^\//, propagationTargets],
    },
  },
}
