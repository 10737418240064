import './local-storage.patch'

type NaveggConfig = {
  acc: string
  naveggReady?: boolean
  navegg?: any
}

let initializedAccName: string

export default function naveggPlugin(userConfig: NaveggConfig) {
  const defaultScriptSrc = 'https://tag.navdmp.com/universal.min.js'

  return {
    name: 'analytics-plugin-tegrus-navegg',
    config: { ...userConfig },
    initialize: ({ config, instance }) => {
      const { acc } = config
      if (!acc) {
        throw new Error('No navegg acc defined')
      }

      // load naveg script
      if (scriptLoaded(acc, defaultScriptSrc)) {
        return
      }

      /**
       * Listen to localstorage changes triggerd by Navegg then normalize data and persist to user traits
       */
      addEventListener('localstorage', async (event: CustomEvent) => {
        if (event.detail?.key !== getNaveggPersonaKey(acc)) {
          return
        }

        // normalize Navegg data with locadatabase
        const nvgpersona = await normalizeNaveggData(acc)

        // update user trais with normalized navegg data
        await instance.identify({ persona: nvgpersona })
      })
      //
      // Navegg script
      // ------------------------------------------
      ;(function (n, v, g) {
        let o = 'Navegg'
        if (!n[o]) {
          let a = v.createElement('script')
          a.src = g
          let b = document.getElementsByTagName('script')[0]
          b.parentNode.insertBefore(a, b)
          n[o] =
            n[o] ||
            function (parms) {
              n[o].q = n[o].q || []
              n[o].q.push([this, parms])
            }
        }
      })(window, document, defaultScriptSrc)
      window['naveggReady'] = window['naveggReady'] || []
      window[`nvg${acc}`] = eval(`new Navegg({ acc: ${acc}})`)

      // --------------------------------------------
      initializedAccName = acc // save in order to retrive later on loaded hook method
    },
    loaded: () => {
      return scriptLoaded(initializedAccName, defaultScriptSrc) && window['naveggReady']
    },
  }
}

/**
 *
 */
const normalizeNaveggData = async (acc: string) => {
  const { datanvg } = await import('./navegg-data')

  const key = getNaveggPersonaKey(acc)
  const nvgpersona = readFromStorage(key)
  const nvgaudiences = Object.assign({}, nvgpersona)

  if (nvgpersona) {
    for (const [key, value] of Object.entries(nvgpersona)) {
      if (Array.isArray(value)) {
        nvgpersona[key] = value.map((item) => {
          const find = datanvg.find((d) => d.id === item)
          if (find) {
            return find['nome']
          }
          return item
        })
      } else {
        const find = datanvg.find((d) => d.id === value)
        if (find) {
          nvgpersona[key] = find['nome']
        }
      }
    }

    for (const [key, value] of Object.entries(nvgaudiences)) {
      if (Array.isArray(value)) {
        nvgaudiences[key] = value.map((item) => {
          const find = datanvg.find((d) => d.id === item)
          if (find) {
            return find['DV360']
          }
          return item
        })
      } else {
        const find = datanvg.find((d) => d.id === value)
        if (find) {
          nvgaudiences[key] = find['DV360']
        }
      }
    }
  }

  // save normalized data to local storage
  writeToStorage('persona', nvgpersona)
  writeToStorage('audience', nvgaudiences)

  return nvgpersona
}

const regexCache = {}
/**
 *
 */
function scriptLoaded(acc: string, scriptSrc: string) {
  let regex = regexCache[acc]
  if (!regex) {
    const scriptSrcEscaped = scriptSrc
      .replace(/^https?:\/\//, '')
      .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    regex = new RegExp(scriptSrcEscaped)
    regexCache[acc] = regex
  }
  const scripts = document.querySelectorAll('script[src]')
  const isLoaded = !!Object.keys(scripts).filter((key) => (scripts[key].src || '').match(regex))
    .length
  return isLoaded
}

/**
 *
 */
const readFromStorage = (key: string) => {
  return JSON.parse(window.localStorage.getItem(key))
}

/**
 *
 */
const writeToStorage = (key: string, value: any) => {
  localStorage.setItem(key, value ? JSON.stringify(value) : null)
}

/**
 *
 */
const getNaveggPersonaKey = (acc: string) => `nvgpersona${acc}`
