/**
 *
 */
export default function lastClickPlugin(userConfig = {}) {
  return {
    name: 'analytics-plugin-tegrus-last-click',
    config: Object.assign({}, userConfig),
    bootstrap: ({ instance }) => {
      onMouseClick((click) => {
        instance.track('User Clicked', click)
        instance.identify({ last_click: click })
      })
    },
  }
}

/**
 *
 */
export function onMouseClick(cb) {
  if (typeof window === 'undefined') return

  document.addEventListener('click', (e) => {
    const dataset = (e.target as HTMLElement).dataset

    if (!dataset.type) {
      return false
    }
    const { pathname } = new URL(window.location.href)

    let last_click = {
      type: dataset.type,
      position: dataset.position,
      section: dataset.section,
      label: dataset.label,
      path: pathname,
    }
    return cb(last_click)
  })
}
