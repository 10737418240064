// ref: https://stackoverflow.com/a/69380917
/**
 * Add event listeners do Storage methods
 */

Storage.prototype.setItem = new Proxy(Storage.prototype.setItem, {
  apply(target, thisArg, argumentList) {
    const event = new CustomEvent('localstorage', {
      detail: {
        key: argumentList[0],
        oldValue: thisArg.getItem(argumentList[0]),
        newValue: argumentList[1],
        type: 'setItem',
      },
    })
    window.dispatchEvent(event)
    return Reflect.apply(target, thisArg, argumentList)
  },
})

Storage.prototype.removeItem = new Proxy(Storage.prototype.removeItem, {
  apply(target, thisArg, argumentList) {
    const event = new CustomEvent('localstorage', {
      detail: {
        key: argumentList[0],
        type: 'removeItem',
      },
    })
    window.dispatchEvent(event)
    return Reflect.apply(target, thisArg, argumentList)
  },
})

Storage.prototype.clear = new Proxy(Storage.prototype.clear, {
  apply(target, thisArg, argumentList) {
    const event = new CustomEvent('localstorage', {
      detail: {
        key: '__all__',
        type: 'clear',
      },
    })
    window.dispatchEvent(event)
    return Reflect.apply(target, thisArg, argumentList)
  },
})

export {}
