import { getSession } from '@analytics/session-utils'
import { Referrer } from './parse-referrer'
/**
 *
 */
export default function utmPlugin(userConfig) {
  return {
    name: 'analytics-plugin-tegrus-utm',
    config: { ...userConfig },
    initialize: async ({ instance }) => {
      const currentSession = getSession()

      if (currentSession.isNew) {
        const default_utm = {
          utm_source: 'Direct',
          utm_medium: 'Direct',
          utm_campaign: 'Direct',
          utm_content: 'Direct',
          utm_term: 'Direct',
          utm_marketing_tactic: 'Direct',
          utm_creative_format: 'Direct'
        }

        const utm_params = {}

        for (const [key, value] of new URL(window.location.href).searchParams.entries()) {
          if (key.startsWith('utm') || key.startsWith('ttuid')) {
            utm_params[key] = value
          }
        }

        if (Object.keys(utm_params).length === 0 && document.referrer) {
          const referrer = new Referrer(document.referrer, window.location.href)
          utm_params.utm_source = referrer.referrer
          utm_params.utm_medium = referrer.medium
          utm_params.utm_term = referrer.search_term
        }

        const utm_object = { ...default_utm, ...utm_params }
        const identify_utm = {}

        const traits = instance.user().traits

        if (!traits?.first_utm) {
          // console.log('Nova sessão e novo usuário');
          for (const [key, value] of Object.entries(utm_object)) {
            identify_utm[`current_${key}`] = value
            identify_utm[`first_${key}`] = value
          }
        } else {
          // console.log('Nova sessão e usuário antigo');
          for (const [key, value] of Object.entries(utm_object)) {
            identify_utm[`current_${key}`] = value
          }
        }

        await instance.identify(identify_utm)
        return
      } else {
        // console.log('Usuário continuando a navegação');
        return
      }
    },
  }
}
